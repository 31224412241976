import React from 'react';
import Container from '@mui/material/Container';

const Contact = () => {
  return (
    <Container>
      <h1>Coming Soon...</h1>
    </Container>
  );
};

export default Contact;
